import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "maksimov_torpedo" }) {
    ...NewsInfoEn
    innerImages {
      maksimov {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    const { maksimov } = this.props.data.news.innerImages
    return (
      <div className="row">
        <div className="col-xl-12">
          <p><Link to="/en/players/maksimov">Maksim Maksimov</Link>, a client of Catenaccio Sports Agency, has signed a two-year contract with Torpedo Moscow.</p>
          <p>After signing the contract, Maximov told the club press service: “I am very happy to join a club with such a long history. I feel a lot of positive emotions, thanks to the management and coaching staff. Now the main task is to regain optimal physical conditions and help the team, get on the pitch and play. I played in European leagues for seven years, and I think that returning to Russia and, especially, moving to a club like Torpedo is a good option to continue my career.</p>
          <p>There's a lot of familiar faces in the team, players coming from Voronezh in particular. These are Roma Shishkin, Ruslan Magal, Dmitry Tsitsilin. In the training process, I already managed to talk with the other guys. The team is very friendly, I was well received, so there are no difficulties. I’m really looking forward to when I have the opportunity to get on the pitch as a part of Torpedo and prove myself!”</p>
          
          <Img fluid={ maksimov.childImageSharp.fluid } alt="Максим Максимов" width="100%" />

          <p>Earlier, FIFA banned Maximov from playing for professional clubs because of his transfer from Trakai to Vardar in the summer of 2017. The forward terminated the agreement with Trakai in accordance with the contract terms a day before the transfer window was closed and transferred to Vardar as a free agent. In early August, Maximov, with Catenaccio's support, <Link to="/en/news/maksimov_vs_fifa">successfully appealed FIFA's decision</Link>.</p>
          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/ZtDLQKa0of4"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}